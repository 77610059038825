import React, { useState } from 'react'
import styled from 'styled-components'
import { Loading, NavBar, Header, ScrollIndicator, Intro, About, Projects, ContactMenu, Footer } from '@components';

const Spacer = styled.div`
  height: ${props => props.height || 0};
`

const IndexPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {/* <Loading setIsLoading={setIsLoading} /> */}

      <NavBar />
      <ScrollIndicator />
      {/* <SubText>what next?</SubText> */}

      <Header />

      <div id="intro" />
      <Intro />

      <div id="about" />
      <About />

      {/* <div id="technologies" /> */}

      <div id="projects" />
      <Projects />

      <div id="contact" />
      <ContactMenu />

      {/* <Spacer height={"200px"}/> */}

      <Footer />
    </>
  )
}

export default IndexPage